<template>
  <!--a pop 2b 帶位安排 選目的地-->
  <div :class="{'modal-ui': true, 'is-active': isActive}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>帶位安排</h3>
          <button class="modal-ui__btn-close" @click="onClose()"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>診間</h4>
          </div>
          <div class="modal-ui__office-list">
            <button v-for="(spot, index) in roomSpots" :key="index" class="modal-ui__btn-office" :class="{ 'is-active': spot.id === selectSpotId, 'is-occupied': spot.status !== 'AVAILABLE' }"  @click="onSelectSpot(spot)">{{spot.name}}({{spot.location}})</button>
          </div>
          <div class="modal-ui__row">
            <h4>休息區</h4>
          </div>
          <div class="modal-ui__office-list">
            <button v-for="(spot, index) in restAreaSpots" :key="index" class="modal-ui__btn-office" :class="{ 'is-active': spot.id === selectSpotId, 'is-occupied': spot.status !== 'AVAILABLE' }"  @click="onSelectSpot(spot)">{{spot.name}}({{spot.location}})</button>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-confirm" @click="onConfirm()">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 2b 帶位安排-->
</template>
<script>
import { apiGetSpots } from '../api/v1/reservations'

export default {
  name: 'AddTaskSelectSpot',
  props: ['params', 'isActive'],
  emits: ['on-close', 'on-confirm'],
  data () {
    return {
      selectSpotId: 0,
      spots: []
    }
  },
  computed: {
    spotType () {
      return this.params.spotType ? this.params.spotType : 'ROOM'
    },
    restAreaSpots () {
      return this.spots.filter(s => s.type === 'REST_AREA')
    },
    roomSpots () {
      return this.spots.filter(s => s.type === 'ROOM')
    }
  },
  watch: {
    isActive: function (nv, ov) {
      console.log(`isactive nv ${nv}, ov ${ov}`)
      if (nv === true) {
        this.fetchData()
      } else {
        this.resetData()
      }
    }
  },
  methods: {
    fetchData () {
      apiGetSpots({}).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.spots = data // data.filter(spot => spot.type === this.spotType)
        }
      })
    },
    // 清除選擇
    resetData () {
      this.selectJobId = 0
    },
    onConfirm () {
      const params = this.params
      params.spotId = this.selectSpotId
      this.$emit('on-confirm', params)
    },
    onClose () {
      this.$emit('on-close')
    },
    onSelectSpot (spot) {
      this.selectSpotId = spot.id
      if (spot.status !== 'AVAILABLE') {
        this.$swal.fire({
          title: '佔用中',
          text: `${spot.name} 目前可能沒有空位(${spot.status})，確定要設為目的地嗎？`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: '確定',
          cancelButtonText: '取消'
        }).then((result) => {
          if (result.isConfirmed) {
            this.doSelectSpot(spot.id)
          }
        })
      } else {
        this.doSelectSpot(spot.id)
      }
    },
    doSelectSpot (spotId) {
      this.selectSpotId = spotId
    }
  }
}
</script>
<style lang="scss" scoped>
  .is-occupied {
      color: #b6b6b6;
  }
</style>
