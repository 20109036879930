<template>
  <div class="panel__control">
    <div class="panel__account">
      <img class="panel__account-avatar" :src="photo" :onerror="loadDefaultPhoto()">
      <span class="panel__account-name">{{username}}</span>
    </div>
    <button :class="{ 'panel__btn-toggle': true, 'is-active': isShowMenu }" @click="toggleMenu()">
      <span></span>
    </button>
    <nav :class="{ 'header-nav': true, 'is-active': isShowMenu }" @mouseleave="isShowMenu = false">
      <router-link v-if="auth('wscreen')" class="header-nav__btn" :class="{'is-active':(activeItem === 'wscreen')}" to="/wscreen">進行中 / 等候中</router-link>
      <router-link v-if="auth('nscreen')" class="header-nav__btn" :class="{'is-active':(activeItem === 'nscreen')}" to="/nscreen">即將報到 / 逾期未報到 / 預約</router-link>
      <router-link v-if="auth('daily-report')" class="header-nav__btn" :class="{'is-active':(activeItem === 'daily-report-medical')}"  to="/daily-report-medical">醫療總表</router-link>
      <router-link v-if="auth('daily-report')" class="header-nav__btn" :class="{'is-active':(activeItem === 'daily-report-beauty')}"  to="/daily-report-beauty">美療總表</router-link>
      <a class="header-nav__btn" href="#" @click="logout()">登出</a>
    </nav>
  </div>
</template>
<script>
import { AuthService } from '@/service/auth.service'
import { Role } from '../constants/role'

export default {
  name: 'FrontDeskMenu',
  props: ['activeItem'],
  data () {
    return {
      isShowMenu: false, // 主選單
      photo: ''
    }
  },
  created () {
    this.isPC()
    console.log(window.innerWidth)
  },
  computed: {
    username () {
      return this.$store.state.auth.user ? this.$store.state.auth.user.full_name : ''
    }
  },
  methods: {
    isPC () {
      if (window.innerWidth > 1200) {
        return true
      } else {
        return false
      }
    },
    toggleMenu () {
      this.isShowMenu = !this.isShowMenu
      console.log(this.$store.state.auth.user.roles[0])
      console.log(this.activeItem)
    },
    auth (path) {
      switch (path) {
        case 'wscreen':
          return this.isPC() && [Role.Admin, Role.FrontDesk].includes(this.$store.state.auth.user.roles[0])
        case 'nscreen':
          return [Role.Admin, Role.Staff, Role.FrontDesk].includes(this.$store.state.auth.user.roles[0])
        case 'daily-report':
          // return this.isPC() && [Role.Admin, Role.Staff, Role.FrontDesk].includes(this.$store.state.auth.user.roles[0])
          return [Role.Admin, Role.Staff, Role.FrontDesk].includes(this.$store.state.auth.user.roles[0])
        default:
          return false
      }
    },
    loadDefaultPhoto () {
      this.photo = (this.$store.state.auth.user.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
    },
    logout () {
      AuthService.logout('Login')
    }
  }
}
</script>
