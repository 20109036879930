<!-- 等候中 -->
<template>
  <!--card-諮詢師-->
  <div class="card card--wait">
    <header class="card__header">
      <div class="card__avatar-wrap">
        <div class="card__avatar">
          <img v-bind:src="photo" alt="">
        </div>
        <div class="card__vip">
          <p>{{reservation.patientType}}</p>
          <p>{{reservation.patientPhoneLast4Digits}}</p>
        </div>
      </div>
      <div class="card__detail">
        <div class="card__info">
          <span class="card__name">{{reservation.patientName}}</span>
          <span :class="serviceStaffColorClass">{{reservation.serviceStaffName}}</span>
          <button :class="{'card-btn-menu':true, 'is-active':isCardMenuActive}" @click="toggleCardMenu"></button>
          <div :class="{'card-option':true, 'is-active':isCardMenuActive}" @mouseleave="isCardMenuActive = false">
            <button class="card-option__btn" @click="setInfusion">點滴設定</button>
            <button class="card-option__btn" @click="proceedHere">原地進行</button>
            <button class="card-option__btn" @click="onCancel">取消</button>
            <button class="card-option__btn" @click="onChangeTreatment">更改療程</button>
          </div>
        </div>
        <div class="card__course">
          <span class="card__course-name">[{{reservation.id}}] {{reservation.treatmentName}}</span>
          <span class="card__doctor-name">&nbsp;{{reservation.treatmentStaffName}}</span>
        </div>
        <div class="card__switch" v-if="combo.length > 1">
          <button class="card__btn-prev" :class="{ disabled: reservationIndex === 0}" @click="comboPrev"></button>
          <button class="card__btn-next" :class="{ disabled: reservationIndex === combo.length-1}" @click="comboNext"></button>
        </div>
        <div class="card__location" :class="{step1: (!movingTo || movingTo.status == 'ASSIGNED'), step2: (movingTo && movingTo.status == 'INPROGRESS')}">
          位置：<span>{{reservation.spotName}}</span><span v-if="movingTo"></span><span v-if="movingTo">{{movingTo.spotName}}</span>
        </div>
        <div class="card__flow step1">
          步驟：<span>{{reservation.nowTreatmentFlowName}}</span><span></span><span>{{reservation.nextTreatmentFlowName}}</span>
        </div>
      </div>
    </header>
    <div class="card__status">
      <div :class="statusColorClass"></div>
      <div class="card__status-detail">
        <span class="card__status-reservation">預約：<strong>{{reservation.scheduleTime}}</strong></span>
        <span class="card__status-timeout">到診計：<strong>{{minutesAfterAttended}}min</strong></span>
        <span class="card__status-register">報到：<strong>{{reservation.attendTime}}</strong></span>
        <span class="card__status-waiting" :class="{delayed: isNowDelay}">等下步：<strong>{{reservation.waitMin}}min</strong></span>
      </div>
    </div>
    <footer class="card__footer">
      <button class="card__btn card__btn--people" @click="gotoRoom">{{gotoSomewhereLabel}}</button>
      <!-- <button class="card__btn card__btn--people" @click="gotoRoom">{{gotoRoomLabel}}</button>
      <button class="card__btn card__btn--people" @click="gotoRestArea">{{gotoRestAreaLabel}}</button> -->
      <span v-for="infusionTask in filteredInfusionTasks" :key="infusionTask">
        <button v-if="infusionTask.staffId===null" class="card__btn card__btn--assign" @click="setInfusionTask(infusionTask)">請指派</button>
        <button v-else class="card__btn card__btn--assign" @click="setInfusionTask(infusionTask)">{{infusionTask.staffName}}</button>
      </span>
    </footer>
  </div>
  <!--card-->
</template>

<style scoped>
.delayed {
  color: red;
}
.card__footer {
  gap: 0.6rem;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>

<script>
import { mapActions } from 'vuex'
import { photoUrl } from '@/api/v1/reservations'
import { formatDateTime } from '../utils/utils'

export default {
  components: { },
  name: 'card-wait',
  props: ['combo', 'infusionTasks'],
  emits: ['goto-rest-area', 'goto-room', 'set-infusion', 'set-infusion-task', 'proceed-here', 'cancel', 'change-treatment'],
  data () {
    return {
      isCardMenuActive: false,
      reservationIndex: 0
    }
  },
  computed: {
    reservation () {
      return this.combo[this.reservationIndex]
    },
    filteredInfusionTasks () {
      return this.infusionTasks.filter(task => task.reservationId === this.combo[0].id)
    },
    statusColorClass () {
      if (this.reservation.attendTime > this.reservation.scheduleTime) {
        return 'status-square status-square--d'
      }
      if (this.reservation.waitMin >= 30) {
        return 'status-square status-square--a'
      } else if (this.reservation.waitMin >= 15) {
        return 'status-square status-square--b'
      } else if (this.reservation.waitMin >= 5) {
        return 'status-square status-square--c'
      } else {
        return 'status-square status-square--c'
      }
    },
    serviceStaffColorClass () {
      switch (this.reservation.serviceStaffJob) {
        case '醫生':
          return 'identity identity--doctors'
        case '護理師':
          return 'identity identity--nurse'
        case '美容師':
          return 'identity identity--beautician'
        case '諮詢師':
          return 'identity identity--consultant'
        default:
          return 'identity identity--control'
      }
    },
    photo () {
      if (this.reservation.patientPhoto) {
        return photoUrl('patients', this.reservation.patientId)
      } else {
        // use default profile head icon
        return (this.reservation.patientGender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
      }
    },
    movingTo () {
      return this.reservation.tasks.find(task => (task.taskType === 'TAKETO' && (task.status === 'ASSIGNED' || task.status === 'INPROGRESS')))
    },
    gotoSomewhereLabel () {
      const takeToTask = this.reservation.tasks.find(task => (task.taskType === 'TAKETO' && (task.status === 'ASSIGNED' || task.status === 'INPROGRESS')))
      if (takeToTask) {
        return `${takeToTask.staffName}`
      }
      return '請指派'
    },
    gotoRoomLabel () {
      const takeToTask = this.reservation.tasks.find(task => (task.taskType === 'TAKETO' && (task.status === 'ASSIGNED' || task.status === 'INPROGRESS') && task.spotType === 'ROOM'))
      if (takeToTask) {
        return `${takeToTask.staffName}`
      }
      return '去診間'
    },
    gotoRestAreaLabel () {
      const takeToTask = this.reservation.tasks.find(task => (task.taskType === 'TAKETO' && (task.status === 'ASSIGNED' || task.status === 'INPROGRESS') && task.spotType === 'REST_AREA'))
      if (takeToTask) {
        return `${takeToTask.staffName}`
      }
      return '去休息區'
    },
    toBeTakenTo () {
      // 正在等候被帶位去下一步驟
      if (this.reservation.isWaiting && this.reservation.status === 'INPROGRESS') {
        return this.reservation.nowTreatmentFlowName
      }
      return ''
    },
    // NOW 是否已經 delay 了
    isNowDelay () {
      const now = new Date()
      const dd = formatDateTime(now, this.reservation.nowTreatmentEnd)
      const shouldEnd = new Date(dd)
      return (shouldEnd < now)
    },
    minutesAfterAttended () {
      const now = new Date()
      const dd = formatDateTime(now, this.reservation.attendTime, '/')
      const diffMs = now - (new Date(dd))
      return Math.floor((diffMs / 1000) / 60)
    }
  },
  methods: {
    comboNext () {
      this.reservationIndex++
      if (this.reservationIndex >= this.combo.length) {
        this.reservationIndex = this.combo.length - 1
      }
    },
    comboPrev () {
      this.reservationIndex--
      if (this.reservationIndex < 0) {
        this.reservationIndex = 0
      }
    },
    toggleCardMenu () {
      this.isCardMenuActive = !this.isCardMenuActive
    },
    gotoRoom () {
      this.$emit('goto-room', this.reservation)
    },
    gotoRestArea () {
      this.$emit('goto-rest-area', this.reservation)
    },
    setInfusion () {
      this.isCardMenuActive = false
      this.$emit('set-infusion', this.reservation)
    },
    setInfusionTask (infusionTask) {
      infusionTask.spotId = this.reservation.spotId
      infusionTask.spotName = this.reservation.spotName
      this.$emit('set-infusion-task', infusionTask)
    },
    // 原地進行
    proceedHere () {
      this.$emit('proceed-here', this.reservation)
    },
    // 取消預約
    onCancel () {
      this.$emit('cancel', this.reservation)
    },
    // 更改療程
    onChangeTreatment () {
      this.$emit('change-treatment', this.reservation)
    },
    ...mapActions(
      'modalUI', ['showPop', 'closePop']
    )
  }
}

</script>
