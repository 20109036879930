// iOS only accept '/', other systems accepts '-'
export const formatDate = (d, delimiter = '-') => {
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) { month = '0' + month }
  if (day.length < 2) { day = '0' + day }

  return [year, month, day].join(delimiter)
}

export const formatDateTime = (d, t, delimiter = '-') => {
  const datePart = formatDate(d, delimiter)
  return `${datePart} ${t}:00`
}

export const formatDateYYYYMMDDHHmmss = (dt) => {
  return `${dt.getFullYear().toString().padStart(4, '0')}/${
    (dt.getMonth() + 1).toString().padStart(2, '0')}/${
    dt.getDate().toString().padStart(2, '0')} ${
    dt.getHours().toString().padStart(2, '0')}:${
    dt.getMinutes().toString().padStart(2, '0')}:${
    dt.getSeconds().toString().padStart(2, '0')}`
}

export const formatTimeHHmm = (d) => {
  let hh = '' + d.getHours()
  let mm = '' + d.getMinutes()

  if (hh.length < 2) { hh = '0' + hh }
  if (mm.length < 2) { mm = '0' + mm }

  return [hh, mm].join(':')
}

export const formatTWBirthDay = (bdate) => {
  if (!bdate) {
    return ''
  }
  const parts = bdate.split('-')
  if (parts.length !== 3) {
    return bdate
  }
  const y = parts[0] > 1911 ? (parts[0] - 1911) : parts[0]
  return `${y}.${parts[1]}.${parts[2]}`
}

export const serviceStaffColorClass = (jobName) => {
  switch (jobName) {
    case '醫生':
      return 'identity identity--doctors'
    case '護理師':
      return 'identity identity--nurse'
    case '美容師':
      return 'identity identity--beautician'
    case '諮詢師':
      return 'identity identity--consultant'
    default:
      return 'identity identity--control'
  }
}
