<template>
  <div :class="{'modal-ui': true, 'is-active': isActive}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>請選擇約診項目</h3>
          <div class="modal-ui__filter-group">
            <button class="modal-ui__btn-filter" :class="{ 'is-active': popMenuIsActive }" @click="togglePopMenu()">{{selectTreatmentTypeName}}</button>
            <div class="modal-ui__sub-nav" :class="{ 'is-active': popMenuIsActive }" >
              <button v-for="(treatmentType, index) in [{name: '全部'}, ...treatmentTypes]" :key="index" class="modal-ui__btn-option" @click="onSelectTreatmentType(treatmentType)">{{treatmentType.name}}</button>
            </div>
          </div>
          <div class="site-search">
            <input type="text" v-model="keyword">
            <button>搜尋</button>
          </div>
          <button class="modal-ui__btn-close" @click="onClose"></button>
        </header>
        <div class="modal-ui__content scroll">
          <div class="modal-ui__iv" v-for="(treatmentType, index) in filteredTreatmentTypes" :key="index">
            <div class="modal-ui__row">
              <h4>{{treatmentType.name}}</h4>
            </div>
            <div class="modal-ui__iv-list">
              <button v-for="treatment in treatmentType.treatments.filter(h => h.name.includes(this.keyword))" :key="treatment" class="modal-ui__btn-iv" :class="{ 'is-active': selectedTreatment.id === treatment.id }" @click="onSelectTreatment(treatment)">{{treatment.name}}</button>
            </div>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-confirm" @click="onConfirm">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { apiGetTreatmentTypes } from '../api/v1/reservations'

export default {
  name: 'SelectTreatmentDialog',
  props: ['reservation', 'isActive'],
  emits: ['on-close', 'on-confirm'],
  data () {
    return {
      popMenuIsActive: false,
      selectTreatmentTypeName: '全部',
      keyword: '',
      selectedTreatment: '',
      treatmentTypes: []
    }
  },
  computed: {
    filteredTreatmentTypes () {
      return this.treatmentTypes.filter(t => (this.selectTreatmentTypeName === '全部' || t.name === this.selectTreatmentTypeName) && (t.name.includes(this.keyword) || t.treatments.filter(h => h.name.includes(this.keyword)).length > 0))
    }
  },
  watch: {
    isActive: function (nv, ov) {
      console.log(`isactive nv ${nv}, ov ${ov}`)
      if (nv === true) {
        this.fetchData()
      } else {
        this.resetData()
      }
    }
  },
  methods: {
    async fetchData () {
      try {
        this.treatmentTypes = await apiGetTreatmentTypes()
      } catch (err) {
        console.log(err)
      }
    },
    togglePopMenu () {
      this.popMenuIsActive = !this.popMenuIsActive
    },
    onSelectTreatmentType (treatmentType) {
      this.selectTreatmentTypeName = treatmentType.name
      this.popMenuIsActive = false
    },
    resetData () {
      this.selectedTreatment = ''
    },
    onConfirm () {
      if (this.selectedTreatment) {
        this.$emit('on-confirm', this.selectedTreatment)
      } else {
        this.$swal('請選擇約診項目', '', 'warning')
      }
    },
    onClose () {
      this.$emit('on-close')
    },
    onSelectTreatment (treatment) {
      this.selectedTreatment = treatment
    }
  }
}

</script>
<style scoped>
.modal-ui__btn-filter, .modal-ui__sub-nav button {
  width: 12rem;
}
</style>
