<template>
  <div class="wide-screen">
    <!--等候中-->
    <div class="panel panel--wait">
      <header class="panel__header">
        <h2>等候中</h2>
      </header>
      <main
        class="panel__main panel__main--card"
        :class="{ 'panel__main--empty': waitingCombo.length == 0 }"
      >
        <!--card-wait-->
        <template v-for="combo in waitingCombo" :key="combo[0].id">
          <card-wait
            :combo="combo"
            :infusion-tasks="infusionTasks"
            @goto-rest-area="onGotoRestArea"
            @goto-room="onGotoRoom"
            @set-infusion="onSetInfusion"
            @set-infusion-task="onSetInfusionTask"
            @proceed-here="onProceedHere"
            @cancel="onCancel"
            @change-treatment="onChangeTreatment"
          />
        </template>
        <!--card-->
      </main>
    </div>
    <!--等候中-->
    <!--進行中-->
    <div class="panel panel--process">
      <header class="panel__header">
        <h2>進行中</h2>
        <front-desk-menu :active-item="'wscreen'" />
      </header>
      <main
        class="panel__main panel__main--card"
        :class="{ 'panel__main--empty': inProgressCombo.length == 0 }"
      >
        <!--card-process-->
        <template v-for="combo in inProgressCombo" :key="combo[0].id">
          <card-process
            :combo="combo"
            :infusion-tasks="infusionTasks"
            @set-infusion="onSetInfusion"
            @change-staff="onSelectStaff"
            @add-task="onSelectStaff"
            @set-infusion-task="onSetInfusionTask"
            @cancel="onCancel"
            @change-treatment="onChangeTreatment"
          />
        </template>
        <!--card-->
      </main>
    </div>
    <!--進行中-->
    <ModalUI
      :reservation="popReservation"
      :spotType="popSpotType"
      :task="popTask"
    />
    <!-- 選時間 -->
    <add-task-select-time
      :params="addTaskParams"
      :is-active="isShowSelectTime"
      @on-close="onCloseSelectTime"
      @on-confirm="onConfirmSelectTime"
    />
    <!-- 選人 -->
    <add-task-select-staff
      :params="addTaskParams"
      :is-active="isShowSelectStaff"
      @on-close="onCloseAddTask"
      @on-confirm="onConfirmSelectStaff"
    />
    <!-- 選目的地 -->
    <add-task-select-spot
      :params="addTaskParams"
      :is-active="isShowSelectSpot"
      @on-close="onCloseSelectSpot"
      @on-confirm="onConfirmSelectSpot"
    />
    <!-- 變更療程 -->
    <select-treatment-dialog
      :reservation="popReservation"
      :is-active="isShowChangeTreatment"
      @on-close="onCloseChangeTreatment"
      @on-confirm="onConfirmChangeTreatment"
    />
  </div>
</template>
<style scoped>
/* @import '~@/assets/styles/site.css'; */
</style>
<script>
import ModalUI from '@/components/ModalUI.vue'
import CardWait from '../components/CardWait'
import { mapActions } from 'vuex'
import {
  apiGetReservations,
  apiTakeToSpot,
  apiAddTask,
  apiGetInfusionTasks,
  apiPatchInfusionTasks,
  photoUrl,
  apiGetLatestFeedback,
  apiReadFeedback,
  apiModifyTask,
  apiProceedHere,
  apiCancel,
  apiChangeTreatment
} from '../api/v1/reservations'
import { formatDate } from '../utils/utils'
import AddTaskSelectStaff from '../components/AddTaskSelectStaff.vue'
import CardProcess from '../components/CardProcess.vue'
import FrontDeskMenu from '../components/FrontDeskMenu.vue'
import AddTaskSelectSpot from '../components/AddTaskSelectSpot.vue'
import AddTaskSelectTime from '../components/AddTaskSelectTime'
import SelectTreatmentDialog from '../components/SelectTreatmentDialog.vue'

export default {
  name: 'WScreen',
  components: {
    ModalUI,
    CardWait,
    AddTaskSelectStaff,
    CardProcess,
    FrontDeskMenu,
    AddTaskSelectSpot,
    AddTaskSelectTime,
    SelectTreatmentDialog
  },
  data () {
    return {
      waiting: [],
      inProgress: [],
      timer: '',
      popReservation: '', // 目前顯示對話框的預約單
      popSpotType: '', // 去休息區還是去診間
      popTask: '', // pop4a 替換人員
      // addTaskParams: 指派工作 / 指派點滴 / 指派帶位 傳入的參數
      // 指派工作/換人 reservationId, treatmentFlowName, startTime, endTime, spotId
      // 指派點滴     reservationId, treatmentFlowName, startTime, endTime, spotId, infusionOrderId, infusionName
      // 指派帶位     reservationId, treatmentFlowName, startTime, endTime(x), spotId, spotType = 'ROOM' or 'REST_AREA'
      addTaskParams: {},
      isShowSelectTime: false, // 顯示選時間對話框
      isShowSelectStaff: false, // 是否顯示選人對話框
      isShowSelectSpot: false, // 是否顯示選地點對話框
      photo: '',
      infusionTasks: [],
      readingFeedback: null,
      isShowChangeTreatment: false // 顯示變更療程對話
    }
  },
  created () {
    this.loadData()
    this.timer = setInterval(this.loadData, 2500)
    this.photo = photoUrl('staffs', this.$store.state.auth.user.id)
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  computed: {
    username () {
      return this.$store.state.auth.user.full_name
    },
    waitingCombo () {
      return this.makeComboGroups(this.waiting)
    },
    inProgressCombo () {
      return this.makeComboGroups(this.inProgress)
    }
  },
  methods: {
    // 取得登入者照片
    loadDefaultPhoto () {
      this.photo =
        this.$store.state.auth.user.gender === 1
          ? require('@/assets/images/id-pic-man.svg')
          : require('@/assets/images/id-pic-woman.svg')
    },
    // 套餐放一起
    makeComboGroups (reservations) {
      const allCombos = []
      let curComboReservation = ''
      let curComboIndex = -1
      reservations.forEach((r) => {
        if (
          curComboReservation &&
          curComboReservation.patientId === r.patientId &&
          curComboReservation.scheduleDate === r.scheduleDate &&
          curComboReservation.scheduleTime === r.scheduleTime
        ) {
          allCombos[curComboIndex].push(r)
        } else {
          curComboReservation = r
          allCombos.push([r])
          curComboIndex++
        }
      })
      return allCombos
    },
    loadData () {
      const today = formatDate(new Date())
      // console.log('loading data...')
      const params = {
        start: today,
        end: today,
        status: 'ATTENDED,INPROGRESS'
      }
      // Load reservataions for today
      apiGetReservations(params).then((data) => {
        // console.log(data)
        if (data.content) {
          // console.log(data.content)
          this.waiting = data.content
            .filter((reservation) => reservation.isWaiting)
            .sort(function (a, b) {
              return b.waitMin - a.waitMin
            })
          this.inProgress = data.content.filter(
            (reservation) =>
              reservation.status === 'INPROGRESS' && !reservation.isWaiting
          )
        } else {
          if (data.error) {
            console.log(data.error.message)
            // alert(data.error.message)
          }
        }
      })
      // Load infusion tasks
      apiGetInfusionTasks({ date: today }).then((data) => {
        // console.log(data)
        if (!data.error) {
          this.infusionTasks = data
        }
      })
      // Load feedbacks
      if (!this.readingFeedback) {
        apiGetLatestFeedback().then((data) => {
          if (!data.error) {
            this.popFeedback(data)
          } else {
            // console.log(`load feedback ... ${data.error.message}`)
          }
        })
      }
    },
    // 顯示狀況回報對話
    popFeedback (data) {
      if (data) {
        this.readingFeedback = data
        this.$swal({
          position: 'top-end',
          title: data.staffName,
          html: `${data.message} <br />(${data.taskDescription})`,
          showConfirmButton: false
        }).then((ok) => {
          apiReadFeedback(data.id).then(() => {
            this.readingFeedback = null
          })
        })
      }
    },
    // Reset addTaskParams
    resetAddTaskParams () {
      this.addTaskParams = {}
    },
    // 點選「去診間」按鈕
    onGotoRestArea (reservation) {
      // console.log('gotoRestArea in WScreen ' + reservation.id)
      this.addTaskParams.reservationId = reservation.id
      this.addTaskParams.spotType = 'REST_AREA'
      this.isShowSelectTime = true
    },
    // 點選「去休息區」按鈕
    onGotoRoom (reservation) {
      // console.log('onGotoRoom in WScreen ' + reservation.id)
      this.addTaskParams.reservationId = reservation.id
      this.addTaskParams.spotType = 'ROOM'
      this.isShowSelectTime = true
    },
    // 關閉選時間對話框
    onCloseSelectTime () {
      this.isShowSelectTime = false
    },
    // 關閉選人對話框
    onCloseAddTask () {
      this.isShowSelectStaff = false
      this.resetAddTaskParams()
    },
    // 關閉選地點對話框
    onCloseSelectSpot () {
      this.isShowSelectSpot = false
    },
    // 點選卡片上的「請指派」按鈕 --> 選人
    onSelectStaff (params) {
      this.addTaskParams = params
      this.isShowSelectStaff = true
    },
    // 開啟選目的地對話框
    onSelectSpot (params) {
      this.addTaskParams = params
      this.isShowSelectSpot = true
    },
    // 選時間完畢: 帶位的第一步（確認時間）=> 回傳 addTaskParams + startTime 已設定
    onConfirmSelectTime (params) {
      this.isShowSelectTime = false
      params.specialtyId = 3 // 帶位
      this.onSelectStaff(params) // 選人
    },
    // 選人完畢
    onConfirmSelectStaff (params) {
      this.isShowSelectStaff = false
      if (params.infusionOrderId) {
        // 指派點滴
        // console.log(`confirm add infusion task ${params.infusionOrderId}`)
        apiPatchInfusionTasks(JSON.parse(JSON.stringify(params)))
          .then((data) => {
            if (data.error) {
              this.$swal('指派失敗', '', 'error')
              console.log(data.error.message)
            } else {
              this.$swal({
                title: data.message,
                timer: 600,
                showConfirmButton: false
              })
              // this.$swal('指派成功', '', 'success')
            }
          })
          .catch((err) => {
            console.log(err)
            this.$swal('指派失敗', '', 'error')
          })
          .finally(() => {
            this.onCloseAddTask()
          })
      } else if (params.spotType) {
        // 帶位，下一步選目的地
        this.onSelectSpot(params)
      } else {
        if (params.taskId) {
          // 換人
          apiModifyTask(params)
            .then((data) => {
              if (data.error) {
                console.log(data.error.message)
              } else {
                this.$swal({
                  title: '指派成功',
                  timer: 600,
                  showConfirmButton: false
                })
              }
            })
            .catch((err) => {
              console.log(err)
              this.$swal('指派失敗', '', 'error')
            })
            .finally(() => {
              this.onCloseAddTask()
            })
        } else {
          // 指派一般工作
          apiAddTask(JSON.parse(JSON.stringify(params)))
            .then((data) => {
              if (data.error) {
                this.$swal('指派失敗', '', 'error')
                console.log(data.error.message)
              } else {
                this.$swal({
                  title: '指派成功',
                  timer: 600,
                  showConfirmButton: false
                })
                // this.$swal('指派成功', '', 'success').then(() => this.onCloseAddTask())
              }
            })
            .catch((err) => {
              console.log(err)
              this.$swal('指派失敗', '', 'error')
            })
            .finally(() => {
              this.onCloseAddTask()
            })
        }
      }
    },
    // 選地點完畢, 指派帶位
    onConfirmSelectSpot (params) {
      // console.log('--- onConfirmSelectSpot ---')
      // console.log(params)
      apiTakeToSpot(params)
        .then((data) => {
          this.isShowSelectSpot = false
          if (data.error) {
            console.log(data.error.message)
          } else {
            console.log(data.message)
            this.$swal({
              title: data.message,
              timer: 600,
              showConfirmButton: false
            })
          }
        })
        .catch((e) => {
          this.$swal('指派失敗', e.data.error.message, 'error')
        })
        .finally(() => {
          this.resetAddTaskParams()
        })
    },
    // 設定點滴
    onSetInfusion (reservation) {
      this.popReservation = reservation
      this.showPop({ modalId: '6a', reservationId: reservation.id })
    },
    // 指派點滴任務
    onSetInfusionTask (infusionTask) {
      console.log(`onSetInfusionTask ${infusionTask.id}`)
      console.log(infusionTask)
      this.addTaskParams = infusionTask
      this.addTaskParams.specialtyId = 7 // 點滴
      this.isShowSelectStaff = true
    },
    // 原地進行
    onProceedHere (reservation) {
      apiProceedHere(reservation.id).then((data) => {
        console.log(data)
      })
    },
    // 取消預約
    onCancel (reservation) {
      // this.doCancel(reservation)
      /// 先警示 ///
      this.$swal
        .fire({
          title: '取消預約',
          text: '確定要取消本次預約嗎？',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'red',
          confirmButtonText: '取消預約',
          cancelButtonText: '返回'
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doCancel(reservation)
          }
        })
    },
    doCancel (reservation) {
      const params = {
        reservationId: reservation.id
      }
      apiCancel(params).then((data) => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          console.log(data.message)
        }
      })
    },
    // 變更療程
    onChangeTreatment (reservation) {
      this.popReservation = reservation
      this.isShowChangeTreatment = true
    },
    onCloseChangeTreatment () {
      this.isShowChangeTreatment = false
    },
    onConfirmChangeTreatment (treatment) {
      console.log(
        `change from ${this.popReservation.treatmentName} to ${treatment.name}`
      )
      this.isShowChangeTreatment = false
      apiChangeTreatment(this.popReservation.id, { treatmentId: treatment.id })
        .then((data) => {
          console.log('Change treatment complete')
        }).catch((err) => {
          console.log(`failed ${err}`)
        })
    },
    ...mapActions('modalUI', ['showPop', 'closePop'])
  },
  mounted () {
    console.log('mounted wscreen')
  }
}
</script>
