<!-- 進行中 -->
<template>
  <div class="card card--process">
    <header class="card__header">
      <div class="card__avatar-wrap">
        <div class="card__avatar">
          <img v-bind:src="photo" alt="" />
        </div>
        <div class="card__vip">
          <p>{{ reservation.patientType }}</p>
          <p>{{ reservation.patientPhoneLast4Digits }}</p>
        </div>
      </div>
      <div class="card__detail">
        <div class="card__info">
          <span class="card__name">{{ reservation.patientName }} </span>
          <span :class="serviceStaffColorClass">{{
            reservation.serviceStaffName
          }}</span>
          <div class="card__timer">到診計：{{ minutesAfterAttended }}min</div>
          <button
            :class="{ 'card-btn-menu': true, 'is-active': isCardMenuActive }"
            @click="toggleCardMenu"
          ></button>
          <div
            :class="{ 'card-option': true, 'is-active': isCardMenuActive }"
            @mouseleave="isCardMenuActive = false"
          >
            <button class="card-option__btn" @click="setInfusion">
              點滴設定
            </button>
            <button class="card-option__btn" @click="onCancel">取消</button>
            <button class="card-option__btn" @click="onChangeTreatment">
              更改療程
            </button>
          </div>
        </div>
        <div class="card__course">
          <span class="card__course-name"
            >[{{ reservation.id }}] {{ reservation.treatmentName }}</span
          >
          <span class="card__doctor-name"
            >&nbsp;{{ reservation.treatmentStaffName }}</span
          >
        </div>
        <div class="card__switch" v-if="combo.length > 1">
          <button
            class="card__btn-prev"
            :class="{ disabled: reservationIndex === 0 }"
            @click="comboPrev"
          ></button>
          <button
            class="card__btn-next"
            :class="{ disabled: reservationIndex === combo.length - 1 }"
            @click="comboNext"
          ></button>
        </div>
      </div>
    </header>
    <div class="card__process card__process--now">
      <div class="card__process-row">
        <span class="card__process-title">now</span>
        <span class="card__process-course"
          >{{ reservation.nowTreatmentFlowName }} ({{ reservation.spotName }})
          <b v-if="isNowOngoing">進行中</b>
          <b v-else-if="isNowDone">已完成</b>
        </span>
        <span
          v-if="isNowDelay"
          class="card__process-time"
          :style="{ color: 'red' }"
          >{{ reservation.nowTreatmentStart }} -
          {{ reservation.nowTreatmentEnd }}</span
        >
        <span v-else class="card__process-time"
          >{{ reservation.nowTreatmentStart }} -
          {{ reservation.nowTreatmentEnd }}</span
        >
      </div>
      <div class="card__process-identity-wrap">
        <span
          v-for="(task, index) in nowTasks"
          :key="index"
          :class="[colorOfJob(task.staffJob), isNowDone ? 'disabled' : '']"
          @click="changeStaff(task, true)"
        >
          {{ task.staffName }}
          <slot v-if="task.staffName.length === 0">請選人</slot>
        </span>
        <slot v-if="nowTasks.length === 0">
          <button class="card__btn card__btn--people" @click="addTask(true)">
            請指派
          </button>
        </slot>
        <span
          v-else-if="!isNowDone && nowTasks.length < 3"
          class="identity identity--add"
          @click="addTask(true)"
          >＋</span
        >
        <span v-for="infusionTask in filteredInfusionTasks" :key="infusionTask">
          <slot v-if="infusionTask.staffId === null">
            <button
              class="card__btn card__btn--assign"
              @click="setInfusionTask(infusionTask)"
            >
              請指派
            </button>
          </slot>
          <button
            v-else
            class="card__btn card__btn--assign"
            @click="setInfusionTask(infusionTask)"
          >
            {{ infusionTask.staffName }}
          </button>
        </span>
      </div>
    </div>
    <div class="card__process card__process--next">
      <div class="card__process-row" v-show="!isFinalFlow">
        <span class="card__process-title">next</span>
        <span class="card__process-course">{{
          reservation.nextTreatmentFlowName
        }}</span>
        <span
          v-if="isNextDelay"
          class="card__process-time"
          :style="{ color: 'red' }"
          >{{ reservation.nextTreatmentStart }} -
          {{ reservation.nextTreatmentEnd }}</span
        >
        <span v-else class="card__process-time"
          >{{ reservation.nextTreatmentStart }} -
          {{ reservation.nextTreatmentEnd }}</span
        >
      </div>
      <div class="card__process-identity-wrap" v-show="!isFinalFlow">
        <span
          v-for="(task, index) in nextTasks"
          :key="index"
          :class="colorOfJob(task.staffJob)"
          @click="changeStaff(task, false)"
        >
          {{ task.staffName }}
          <slot v-if="task.staffName.length === 0">請指派</slot>
        </span>
        <slot v-if="needAssignNextTask">
          <button class="card__btn card__btn--people" @click="addTask(false)">
            請指派
          </button>
        </slot>
        <span
          v-else-if="nextTasks.length < 3"
          class="identity identity--add"
          @click="addTask(false)"
          >＋</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { photoUrl } from '@/api/v1/reservations'
import { formatDateTime } from '../utils/utils'

export default {
  name: 'CardProcess',
  props: ['combo', 'infusionTasks'],
  emits: [
    'set-infusion',
    'change-staff',
    'add-task',
    'set-infusion-task',
    'cancel',
    'change-treatment'
  ],
  data () {
    return {
      treatmentFlows: [
        '報到',
        '卸洗',
        '拍照',
        '敷麻',
        '卸麻',
        '醫師處置',
        '術後鎮定',
        '術後鎮定(卸面膜)',
        '預約|衛教',
        '送客'
      ],
      isCardMenuActive: false,
      reservationIndex: 0
    }
  },
  // watch: {
  //   combo (ov, val) {
  //     for (let i = 0; i < val.length; i++) {
  //       if (val[i].tasks.find(t => t.treatmentFlowName === '醫師處置' && t.status !== 'DONE')) {
  //         this.reservationIndex = i
  //         break
  //       }
  //     }
  //   }
  // },
  mounted () {
    console.log(`mounted ${this.combo[0].id}`)
    for (let i = 0; i < this.combo.length; i++) {
      if (
        this.combo[i].tasks.find(
          (t) => t.treatmentFlowName === '醫師處置' && t.status !== 'DONE'
        )
      ) {
        this.reservationIndex = i
        break
      }
    }
  },
  computed: {
    reservation () {
      return this.combo[this.reservationIndex]
    },
    filteredInfusionTasks () {
      return this.infusionTasks.filter(
        (task) => task.reservationId === this.combo[0].id
      )
    },
    statusColorClass () {
      if (this.reservation.waitMin >= 30) {
        return 'status-square status-square--a'
      } else if (this.reservation.waitMin >= 15) {
        return 'status-square status-square--b'
      } else if (this.reservation.waitMin >= 5) {
        return 'status-square status-square--c'
      } else {
        return 'status-square status-square--d'
      }
    },
    serviceStaffColorClass () {
      return this.colorOfJob(this.reservation.serviceStaffJob)
    },
    nowTasks () {
      // 除了醫師處置外的步驟，都統一使用 combo 中第一個預約來指派
      const tasks =
        this.reservation.nowTreatmentFlowName === '醫師處置'
          ? this.reservation.tasks
          : this.combo[0].tasks
      return tasks.filter((el) => {
        return (
          el.treatmentFlowName === this.reservation.nowTreatmentFlowName &&
          el.taskType === 'SERVICE'
        )
      })
    },
    nextTasks () {
      // 除了醫師處置外的步驟，都統一使用 combo 中第一個預約來指派
      const tasks =
        this.reservation.nextTreatmentFlowName === '醫師處置'
          ? this.reservation.tasks
          : this.combo[0].tasks
      return tasks.filter((el) => {
        return (
          el.treatmentFlowName === this.reservation.nextTreatmentFlowName &&
          el.taskType === 'SERVICE'
        )
      })
    },
    photo () {
      if (this.reservation.patientPhoto) {
        return photoUrl('patients', this.reservation.patientId)
      } else {
        // use default profile head icon
        return this.reservation.patientGender === 1
          ? require('@/assets/images/id-pic-man.svg')
          : require('@/assets/images/id-pic-woman.svg')
      }
    },
    isNowOngoing () {
      if (this.nowTasks.length > 0) {
        return this.nowTasks[0].status === 'INPROGRESS'
      }
      return false
    },
    isNowDone () {
      if (this.nowTasks.length > 0) {
        return this.nowTasks[0].status === 'DONE'
      }
      return false
    },
    isFinalFlow () {
      return this.reservation.nowTreatmentFlowName === '送客'
    },
    needAssignNextTask () {
      return this.nextTasks.length === 0
    },
    // NOW 是否已經 delay 了
    isNowDelay () {
      const now = new Date()
      const dd = formatDateTime(now, this.reservation.nowTreatmentEnd)
      const shouldEnd = new Date(dd)
      return shouldEnd < now
    },
    // NEXT 是否已經 delay 了
    isNextDelay () {
      const now = new Date()
      const dd = formatDateTime(now, this.reservation.nextTreatmentEnd)
      const shouldEnd = new Date(dd)
      return shouldEnd < now
    },
    minutesAfterAttended () {
      const now = new Date()
      const dd = formatDateTime(now, this.reservation.attendTime, '/')
      const diffMs = now - new Date(dd)
      return Math.floor(diffMs / 1000 / 60)
    }
  },
  methods: {
    comboNext () {
      this.reservationIndex++
      if (this.reservationIndex >= this.combo.length) {
        this.reservationIndex = this.combo.length - 1
      }
    },
    comboPrev () {
      this.reservationIndex--
      if (this.reservationIndex < 0) {
        this.reservationIndex = 0
      }
    },
    toggleCardMenu () {
      this.isCardMenuActive = !this.isCardMenuActive
    },
    setInfusion () {
      this.isCardMenuActive = false
      this.$emit('set-infusion', this.combo[0])
    },
    changeStaff (task, isNow) {
      // 已經完成，不准換人
      if (isNow && this.isNowDone) {
        return
      }
      console.log('CardProcess - changeStaff, task')
      console.log(task)
      // 換人
      // this.$emit('change-staff', { reservation: this.reservation, task: task })
      const flowName = isNow
        ? this.reservation.nowTreatmentFlowName
        : this.reservation.nextTreatmentFlowName
      const reservation =
        flowName === '醫師處置' ? this.reservation : this.combo[0]

      // NEW WAY
      const nowSpotId =
        this.nowTasks.length > 0 ? this.nowTasks[0].spotId : reservation.spotId
      const nextSpotId =
        this.nextTasks.length > 0 ? this.nextTasks[0].spotId : null
      const params = {
        reservationId: reservation.id,
        taskId: task.id, // task id to change staff
        treatmentFlowName: flowName,
        startTime: isNow
          ? reservation.nowTreatmentStart
          : reservation.nextTreatmentStart,
        endTime: isNow
          ? reservation.nowTreatmentEnd
          : reservation.nextTreatmentEnd,
        spotId: isNow ? nowSpotId : nextSpotId
      }
      this.$emit('change-staff', params)
    },
    isInSeriesFlow (flowName) {
      const index = this.treatmentFlows.indexOf(flowName)
      console.log(`isInSeriesFlow(${flowName}), index = ${index}`)
      if (index >= 0) {
        // check is the flow step is in range of current treatment's treatment flow series
        const results = this.reservation.treatmentFlowSeries.filter((s) => {
          const beginIndex = this.treatmentFlows.indexOf(s.beginFlow)
          const endIndex = this.treatmentFlows.indexOf(s.endFlow)
          console.log(`begin = ${s.begin}, index = ${beginIndex}`)
          console.log(`end = ${s.end}, index = ${endIndex}`)
          return index >= beginIndex && index <= endIndex
        })
        return results.length > 0
      }
      return false
    },
    // 加人 (Task)
    addTask (isNow) {
      const flowName = isNow
        ? this.reservation.nowTreatmentFlowName
        : this.reservation.nextTreatmentFlowName
      const reservation =
        flowName === '醫師處置' ? this.reservation : this.combo[0]

      const nowSpotId =
        this.nowTasks.length > 0 ? this.nowTasks[0].spotId : reservation.spotId
      const nextSpotId =
        this.nextTasks.length > 0 ? this.nextTasks[0].spotId : null
      console.log(
        `addTask: nowSpotId = ${nowSpotId}, nextSpotId = ${nextSpotId}`
      )
      const isSeries = this.isInSeriesFlow(flowName)
      const params = {
        reservationId: reservation.id,
        treatmentFlowName: flowName,
        startTime: isNow
          ? reservation.nowTreatmentStart
          : reservation.nextTreatmentStart,
        endTime: isNow
          ? reservation.nowTreatmentEnd
          : reservation.nextTreatmentEnd,
        spotId: isNow ? nowSpotId : nextSpotId,
        isSeries: isSeries
      }
      console.log(params)
      this.$emit('add-task', params)
    },
    setInfusionTask (infusionTask) {
      infusionTask.spotId = this.reservation.spotId
      infusionTask.spotName = this.reservation.spotName
      this.$emit('set-infusion-task', infusionTask)
    },
    // 取消預約
    onCancel () {
      this.$emit('cancel', this.reservation)
    },
    // 更改療程
    onChangeTreatment () {
      this.$emit('change-treatment', this.reservation)
    },
    colorOfJob (job) {
      if (job.length === 0) {
        // 請指派
        return 'identity identity--nobody'
      }
      switch (job) {
        case '醫生':
          return 'identity identity--doctors'
        case '護理師':
          return 'identity identity--nurse'
        case '美容師':
          return 'identity identity--beautician'
        case '諮詢師':
          return 'identity identity--consultant'
        case '控場':
          return 'identity identity--control'
        default:
          return 'identity identity--beautician'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.identity {
  margin-right: 5px;
  &--add {
    background-color: white;
  }
  &--urgent {
    background-color: white;
    color: red;
  }
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
// .card__btn--assign {
//   width: 100px;
// }
</style>
