<template>
  <!--a pop 2 遲到人士的人需要先設定的時間-->
  <div :class="{'modal-ui': true, 'is-active': isActive}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>帶位安排</h3>
          <button class="modal-ui__btn-close" @click="onClose()"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>設定時間</h4>
            <input class="modal-ui__input-time" type="time" :value="setTime" @input="setTime = $event.target.value">
          </div>
          <div class="modal-ui__row">
            <p class="modal-ui__text">將於<strong>{{setTime}}</strong>分開始進行。</p>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-next" @click="onConfirm">下一步</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 2 遲到人士的人需要先設定的時間-->
</template>
<script>
import { formatTimeHHmm } from '../utils/utils'

export default {
  name: 'AddTaskSelectTime',
  props: ['params', 'isActive'],
  emits: ['on-close', 'on-confirm'],
  data () {
    return {
      setTime: ''
    }
  },
  computed: {
    spotType () {
      return this.params.spotType ? this.params.spotType : 'ROOM'
    }
  },
  watch: {
    isActive: function (nv, ov) {
      console.log(`isactive nv ${nv}, ov ${ov}`)
      if (nv === true) {
        this.setTime = formatTimeHHmm(new Date())
      } else {
        this.setTime = ''
      }
    }
  },
  methods: {
    onConfirm () {
      const params = this.params
      params.startTime = this.setTime
      this.$emit('on-confirm', params)
    },
    onClose () {
      this.$emit('on-close')
    }
  }
}</script>
